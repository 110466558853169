<template>
    <af-modal-dialog v-model="active">
        <div class="p-8">
            <form class="space-y-6" action="#">
                <template v-if="activeStep == 1">
                    <h3 class="mb-6 text-2xl text-gray-900 font-medium leading-none select-none">Заказать звонок</h3>
                    <af-input v-model="patientName">
                        <template v-slot:label>Как к вам обращаться</template>
                    </af-input>
                    <af-phone-input v-model="patientPhoneNumber">
                        <template v-slot:label>Номер мобильного телефона</template>
                    </af-phone-input>
                    <af-button v-model="fetchButtonState">Отправить</af-button>
                    <div class="text-sm text-gray-500 select-none">
                        Заполняя данную форму вы соглашаетесь c&nbsp;условиями
                        <a class="text-blue-700 hover:underline" href="/oklinike/docs/pk.html" target="_blank"> обработки персональных данных</a>
                    </div>
                </template>
                <template v-else>
                    <h3 class="mb-6 text-2xl text-gray-900 font-medium leading-none select-none">Вы заказали обратный звонок</h3>
                    <div class="text-base text-gray-800 select-none">Наш администратор свяжется с&nbsp;вами в&nbsp;течение рабочего времени.</div>
                    <af-button v-model="finishButtonState">Закрыть</af-button>
                </template>
            </form>
        </div>
    </af-modal-dialog>
</template>

<script>
    import axios from 'axios';
	import { API_URL } from '@/constants';
	import { STATE } from '@/constants';

    export default {
        name: 'fetch-callback-dialog',
        props: {
			modalActive: Boolean
		},
		emits: ['modalHide'],
		data() {
			return {
				active: false,
                activeStep: 1,
                patientName: {
					value: null,
					state: STATE.ACTIVE
				},
				patientPhoneNumber: {
					value: null,
					state: STATE.DISABLED
				},
				fetchButtonState: STATE.DISABLED,
                finishButtonState: STATE.READY,
			}
		},
        computed: {
            patientNameState() { return this.patientName.state; },
			patientPhoneNumberState() { return this.patientPhoneNumber.state; }
        },
        watch: {
            modalActive(value) {
                this.active = value;
            },
            active(value) {
                if (!value) {
                    this.activeStep = 1;
                    this.patientName = {
					    value: null,
					    state: STATE.ACTIVE
				    },
				    this.patientPhoneNumber = {
					    value: null,
					    state: STATE.DISABLED
				    },
                    this.fetchButtonState = STATE.DISABLED;
                    this.finishButtonState = STATE.READY,
                    this.$emit('modalHide');
                }
            },
            patientNameState(value) {
                this.patientPhoneNumber.state = (value === STATE.VALID) ? STATE.READY : STATE.DISABLED;
            },
            patientPhoneNumberState(value) {
                this.fetchButtonState = (value === STATE.VALID) ? STATE.READY : STATE.DISABLED;
            },
            fetchButtonState(value) {
                if (value === STATE.CLICKED) {
                    this.fetchCallback();
                }
            },
            finishButtonState(value) {
                if (value === STATE.CLICKED) {
                    this.active = false;
                }
            }
        },
        methods: {
            async fetchCallback() {
                try {
					this.fetchButtonState = STATE.LOADING;
					const response = await axios.post([API_URL, 'callback'].join('/'), {
                        patient_name: this.patientName.value,
                        phone_number: this.patientPhoneNumber.value
                    });
					if (response.status === 200 && this.active) {
						++this.activeStep;
					}
				} catch (error) {
					console.error(`callbackDialog: fetchCallback() catch ${error}`);
				} finally {
                    if (this.active) {
                        this.fetchButtonState = STATE.READY;
                    }
                }
            }
        }
    }
</script>