<template>
	<div class="relative">
		<label class="block mb-1 text-sm font-medium text-slate-900 select-none">Код подтверждения</label>
        <input
            v-imask="mask"
            @input="onInput"
            :readonly="isReadonly"
            :disabled="isDisabled"
            class="w-full h-14 py-3 px-4 text-center text-xl font-medium  text-slate-900 border border-slate-300 focus:border-emerald-500 bg-white outline-none rounded-md shadow-sm disabled:text-slate-700 disabled:bg-slate-50 disabled:shadow-none"
            type="text"
            placeholder="• • • • • •"
            value=""/>
	</div>
</template>

<script>
	import { STATE } from '@/constants';
    import { IMaskDirective } from 'vue-imask';

	export default {
		name: 'af-code-input',
        directives: {
           imask: IMaskDirective
        },
		props: {
			modelValue: Object,
		},
		emits: ['update:modelValue'],
		data: function(){
			return {
                mask: {
                    mask: '0 0 0 0 0 0',
                },
            }
		},
		computed: {
			isDisabled() {
				return this.modelValue.state === STATE.DISABLED;
			},
			isReadonly() {
				return this.modelValue.state === STATE.READONLY;
			},
			isReady() {
				return this.modelValue.state === STATE.READY;
			},
            isValid() {
                return this.modelValue.state === STATE.VALID;
            }
		},
		methods: {
            onInput(event) {
                this.modelValue.value = event.target.value.replace(/\D/g, '').substring(0, 6);
                this.modelValue.state = this.modelValue.value.length === 6 ? STATE.VALID : STATE.READY;
            }
		}
	}
</script>

<style scoped>
    .fade-enter-active {
		animation: fade .2s;
	}
	.fade-leave-active {
		animation: fade .2s reverse;
	}

	@keyframes fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
	}
</style>