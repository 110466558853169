<template>
	<transition name="fade">
		<div v-if="modelValue"
			@click="hide"
			class="flex justify-center items-center fixed inset-x-0 inset-y-0 z-100 bg-slate-900/80 overflow-x-hidden overflow-y-auto z-10" tabindex="-1" aria-hidden="true">
			<div @click.stop
				class="relative mx-auto w-full max-w-md bg-white rounded-md shadow">

				<!-- Close button -->
				<button
					@click="hide"
					type="button"
					tabindex="0"
					class="absolute top-5 right-5 p-1.5 text-sm text-slate-400 hover:text-white focus:text-white bg-transparent hover:bg-slate-400 focus:bg-emerald-500 outline-none rounded items-center transition-colors">
					<svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
					<span class="sr-only">Закрыть</span>
				</button>

				<!-- Content -->
				<slot></slot>
				
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: 'af-modal-dialog',
		props: {
			modelValue: Boolean
		},
		emits: ['update:modelValue'],
		methods: {
			hide(){
				this.$emit('update:modelValue', false);
			}
		}
	}
</script>

<style scoped>
	.fade-enter-active {
		animation: fade .2s;
	}
	.fade-leave-active {
		animation: fade .4s reverse;
	}

	@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
	}

	.fade-enter-active > * {
		animation: grow .2s;
	}

	.fade-leave-active > * {
		animation: grow .4s reverse;
	}

	@keyframes grow {
		0% {
			transform: scale(0);
			opacity: 0;
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}

</style>