<template>
	<div>
		<div class="relative">
			<input
                v-imask="mask"
                @input="onInput"
                :readonly="isReadonly"
				:disabled="isDisabled"
				class="w-full h-14 py-3 pl-4 pr-12 text-left text-basic text-slate-900 border border-slate-300 focus:border-emerald-500 bg-white outline-none rounded-md shadow-sm disabled:text-slate-700 disabled:bg-slate-50 disabled:shadow-none"
				type="text"
                value=""
                placeholder="00:00" />
            
                <!-- Valid icon -->
                <transition name="fade">
                    <div v-if="isValid"
                        class="absolute right-4 inset-y-0 flex items-center pointer-events-none">
                        <svg class="w-6 h-6 text-emerald-500" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    </div>
                </transition>
		</div>
	</div>
</template>

<script>
	import { STATE } from '@/constants';
    import { IMaskDirective } from 'vue-imask';

	export default {
		name: 'af-time-input',
        directives: {
           imask: IMaskDirective
        },
		props: {
			modelValue: Object,
		},
		emits: ['update:modelValue'],
		data: function(){
			return {
                mask: {
                    overwrite: true,
                    autofix: true,
                    mask: 'HH:MM',
                    blocks: {
                        HH: {
                            mask: IMask.MaskedRange,
                            placeholderChar: 'HH',
                            from: 0,
                            to: 23,
                            maxLength: 2
                        },
                        MM: {
                            mask: IMask.MaskedRange,
                            placeholderChar: 'MM',
                            from: 0,
                            to: 59,
                            maxLength: 2
                        }
                    }
                },
            }
		},
		computed: {
            state() {
                return this.modelValue.state;
            },
			isDisabled() {
				return this.modelValue.state === STATE.DISABLED;
			},
			isReadonly() {
				return this.modelValue.state === STATE.READONLY;
			},
			isReady() {
				return this.modelValue.state === STATE.READY;
			},
            isValid() {
                return this.modelValue.state === STATE.VALID;
            }
		},
        watch: {
            state(value) {
                if (value === STATE.READY && this.modelValue.value && this.modelValue.value.length === 5) {
                    this.modelValue.state = STATE.VALID;
                }
            }
        },
		methods: {
            onInput(event) {
                this.modelValue.value = event.target.value.substring(0, 5);
                this.modelValue.state = this.modelValue.value.length === 5 ? STATE.VALID : STATE.READY;  
            },
		}
	}
</script>

<style scoped>
    .fade-enter-active {
		animation: fade .2s;
	}
	.fade-leave-active {
		animation: fade .2s reverse;
	}

	@keyframes fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
	}
</style>