<template>
    <af-modal-dialog v-model="active">
        <div class="p-8" @click="hideDropdowns">
            <form class="space-y-6" action="#">
                <template v-if="activeStep == 1">
                    <h3 class="mb-6 text-2xl text-gray-900 font-medium leading-none select-none">Отменить запись</h3>
                    <af-input v-model="patientName">
                        <template v-slot:label>Как к вам обращаться</template>
                    </af-input>
                    <af-phone-input v-model="patientPhoneNumber">
                        <template v-slot:label>Номер мобильного телефона</template>
                    </af-phone-input>

                    <div>
						<label class="block mb-2 text-sm font-medium text-gray-900 select-none">Дата и время приема</label>
						<div class="grid grid-cols-12 gap-2">
							<div class="col-span-8">
								<af-date-select v-model="date"></af-date-select>
							</div>
							<div class="col-span-4">
								<af-time-input v-model="time"></af-time-input>
							</div>
						</div>
					</div>

                    <af-button v-model="cancelButtonState">Отправить</af-button>
                    <div class="text-sm text-gray-500 select-none">
                        Заполняя данную форму вы соглашаетесь c&nbsp;условиями
                        <a class="text-blue-700 hover:underline" href="/oklinike/docs/pk.html" target="_blank"> обработки персональных данных</a>
                    </div>
                </template>
                <template v-else>
                    <h3 class="mb-6 text-2xl text-gray-900 font-medium leading-none select-none">Вы отменили запись к&nbsp;врачу</h3>
                    <div class="text-base text-gray-800 select-none">Наш администратор свяжется с&nbsp;вами в&nbsp;течение рабочего времени.</div>
                    <af-button v-model="finishButtonState">Закрыть</af-button>
                </template>
            </form>
        </div>
    </af-modal-dialog>
</template>

<script>
    import axios from 'axios';
	import { API_URL } from '@/constants';
	import { STATE } from '@/constants';

    export default {
        name: 'cancel-appointment-dialog',
        props: {
			modalActive: Boolean
		},
		emits: ['modalHide'],
		data() {
			return {
				active: false,
                activeStep: 1,
                patientName: {
					value: null,
					state: STATE.ACTIVE
				},
				patientPhoneNumber: {
					value: null,
					state: STATE.DISABLED
				},
                date: {
					id: null,
                    items: [],
					state: STATE.DISABLED
				},
                time: {
					value: null,
                    items: [],
					state: STATE.DISABLED
				},
				cancelButtonState: STATE.DISABLED,
                finishButtonState: STATE.READY,
			}
		},
        created() {
            const currentDate = new Date();
            let startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const stopDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
            while (startDate <= stopDate) {
                let item = { day: startDate.getDate(), month: startDate.getMonth(), year: startDate.getFullYear() };
                if (item.day === 1) {
                    item.weekDay = startDate.getDay();
                }
                if (startDate >= currentDate && startDate.getDay() > 0) {
                    const monthTitles = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
                    item.id = `${startDate.getDate()} ${monthTitles[startDate.getMonth()]} ${startDate.getFullYear()}`;
                }
                this.date.items.push(item);
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);
            }
        },
        computed: {
            patientNameState() { return this.patientName.state; },
			patientPhoneNumberState() { return this.patientPhoneNumber.state; },
            dateState() { return this.date.state; },
            timeState() { return this.time.state; },
        },
        watch: {
            modalActive(value) {
                this.active = value;
            },
            active(value) {
                if (value) {
                    this.patientName.state = STATE.ACTIVE;
                } else {
                    this.activeStep = 1,
                    this.patientName = {
                        value: null,
                        state: STATE.ACTIVE
                    };
                    this.patientPhoneNumber = {
                        value: null,
                        state: STATE.DISABLED
                    }
                    this.date.id = null;
                    this.date.state = STATE.DISABLED;
                    this.time = {
                        value: null,
                        items: [],
                        state: STATE.DISABLED
                    };
                    this.cancelButtonState = STATE.DISABLED;
                    this.finishButtonState = STATE.READY;
                    this.$emit('modalHide');
                }
            },
            patientNameState(value) {
                this.patientPhoneNumber.state = (value === STATE.VALID) ? STATE.READY : STATE.DISABLED;
            },
            patientPhoneNumberState(value) {
                this.date.state = (value === STATE.VALID) ? STATE.READY : STATE.DISABLED;
            },
            dateState(value) {
                this.time.state = (value === STATE.READY && this.date.id) ? STATE.READY : STATE.DISABLED;
            },
            timeState(value) {
                this.cancelButtonState = (value === STATE.VALID) ? STATE.READY : STATE.DISABLED;
            },
            cancelButtonState(value) {
                if (value === STATE.CLICKED) {
                    this.cancelAppointment();
                }
            },
            finishButtonState(value) {
                if (value === STATE.CLICKED) {
                    this.active = false;
                }
            }
        },
        methods: {
            hideDropdowns() {
                if (this.date.state === STATE.ACTIVE) {
                    this.date.state = STATE.READY;
                }
                if (this.time.state === STATE.ACTIVE) {
                    this.time.state = STATE.READY;
                }
			},
            async cancelAppointment() {
                try {
					this.fetchButtonState = STATE.LOADING;
					const response = await axios.post([API_URL, 'appointment', 'cancel'].join('/'), {
                        patient_name: this.patientName.value,
                        phone_number: this.patientPhoneNumber.value,
                        date: this.date.id,
                        time: this.time.value
                    });
					if (response.status === 200 && this.active) {
						++this.activeStep;
					}
				} catch (error) {
					console.error(`cancelAppointmentDialog: cancelAppointment() catch ${error}`);
				} finally {
                    if (this.active) {
                        this.fetchButtonState = STATE.READY;
                    }
                }
            }
        }
    }
</script>