<template>
	<fetch-appointment-dialog :modal-active="fetchAppointmentActive" :modal-clinic-id="selectedClinic" @modal-hide="hide"></fetch-appointment-dialog>
	<cancel-appointment-dialog :modal-active="cancelAppointmentActive"  @modal-hide="hide"></cancel-appointment-dialog>
	<fetch-callback-dialog :modal-active="fetchCallbackActive" @modal-hide="hide"></fetch-callback-dialog>
</template>

<script>
	export default {
		data() {
			return {
				fetchAppointmentActive: false,
				cancelAppointmentActive: false,
				fetchCallbackActive: false,
				selectedClinic: null,
			}
		},
		methods: {
			show(modal, clinic_id=null) {
				this.fetchAppointmentActive = (modal === 'fetch-appointment');
				this.cancelAppointmentActive = (modal === 'cancel-appointment');
				this.fetchCallbackActive = (modal === 'fetch-callback');
				this.selectedClinic = clinic_id;
			},
			hide() {
				this.fetchAppointmentActive = false;
				this.cancelAppointmentActive = false;
				this.fetchCallbackActive = false;
				this.selectedClinic = null;
			}
		}
	}
</script>

<style></style>