import { createApp } from 'vue';
import App from './App';
import components from '@/components';

const app = createApp(App);
components.forEach(component => app.component(component.name, component));

const afModalDialogs = app.mount('#af-modal-dialog');

document.addEventListener('DOMContentLoaded', () => {
  const showModalButtons = document.querySelectorAll('.show-modal-button');
  for (let i = 0; i < showModalButtons.length; i++) {
    const button = showModalButtons[i];
    button.onclick = function(event) {
      afModalDialogs.show(button.dataset.modal, button.dataset.modalClinic);
    }
  }
});
