import FetchAppointmentDialog from "@/components/FetchAppointmentDialog"
import CancelAppointmentDialog from "@/components/CancelAppointmentDialog"
import FetchCallbackDialog from "@/components/FetchCallbackDialog"
import ModalDialog from "@/components/ModalDialog";
import Select from "@/components/Select";
import DateSelect from "@/components/DateSelect";
import TimeSelect from "@/components/TimeSelect";
import TimeInput from "@/components/TimeInput";
import Button from "@/components/Button";
import Input from "@/components/Input";
import PhoneInput from "@/components/PhoneInput";
import CodeInput from "@/components/CodeInput";

export default [
	FetchAppointmentDialog,
	CancelAppointmentDialog,
	FetchCallbackDialog,
	ModalDialog,
	Select,
	DateSelect,
	TimeSelect,
	TimeInput,
	Button,
	Input,
	PhoneInput,
	CodeInput
]