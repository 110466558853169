<template>
	<af-modal-dialog v-model="active">
		<div class="p-8" @click="onClick">
			<form class="space-y-6" action="#">
				<template v-if="activeStep == 1">
					<h3 class="mb-6 text-2xl text-gray-900 font-medium leading-none select-none">Записаться к&nbsp;врачу</h3>
					<af-select v-model="clinic">
						<template v-slot:label>Клиника</template>
						Выберите клинику
					</af-select>
					<af-select v-model="speciality">
						<template v-slot:label>Специалист</template>
						Выберите специалиста
					</af-select>
					<af-select v-model="doctor">
						<template v-slot:label>Врач</template>
						Выберите врача
					</af-select>
					<div>
						<label class="block mb-2 text-sm font-medium text-gray-900 select-none">Дата и время приема</label>
						<div v-if="date.error"
							class="flex px-3 py-2 text-sm text-gray-900 rounded-md bg-slate-50" role="alert">
							<svg class="flex-shrink-0 inline w-10 h-10 mr-3 text-red-600" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"></path></svg>
							<div>
								<div class="font-medium">В расписании врача нет свободных дат.</div>
								<div class="text-gray-700">
									Попробуйте <button @click="clearDateError" class="inline leading-4 pb-0 text-blue-700 hover:underline">изменить параметры</button>
								</div>
							</div>
						</div>
						<div v-else class="grid grid-cols-12 gap-2">
							<div class="col-span-8">
								<af-date-select v-model="date"></af-date-select>
							</div>
							<div class="col-span-4">
								<af-time-select v-model="time"></af-time-select>
							</div>
						</div>
					</div>
					<af-button v-model="firstButtonState">Далее</af-button>
					<div class="text-sm text-gray-500 select-none">
						Заполняя данную форму вы соглашаетесь c&nbsp;условиями
						<a class="text-blue-700 hover:underline" href="/oklinike/docs/pk.html" target="_blank">обработки персональных данных</a>
					</div>
				</template>
				<template v-else-if="activeStep == 2">
					<h3 class="mb-6 text-2xl text-gray-900 font-medium leading-none select-none">Записаться к&nbsp;врачу</h3>
					<af-input v-model="patientName">
						<template v-slot:label>Имя и фамилия пациента</template>
					</af-input>
					<af-phone-input v-model="patientPhoneNumber">
						<template v-slot:label>Номер мобильного телефона</template>
					</af-phone-input>
					<template v-if="code.state === READY || code.state === VALID">
						<af-code-input v-model="code"></af-code-input>
						<af-button v-model="fetchAppointmentButtonState">Записаться</af-button>
					</template>
					<af-button v-else v-model="fetchCodeButtonState">Получить код</af-button>
					<div class="text-sm text-gray-500 select-none">
						Заполняя данную форму вы соглашаетесь c&nbsp;условиями
						<a class="text-blue-700 hover:underline" href="/oklinike/docs/pk.html" target="_blank">обработки персональных данных</a>
					</div>
				</template>
				<template v-else-if="activeStep == 3">
					<h3 class="mb-6 text-2xl text-gray-900 font-medium leading-none select-none">Вы записаны к врачу</h3>
					<div class="space-y-4">
						<div class="pb-2 border-b border-grey-400">
							<div class="mb-1 text-sm font-medium text-gray-600">Клиника:</div>
							<div class="text-basic text-gray-900">
								{{ clinicTitle }}
							</div>
						</div>
						<div class="pb-2 border-b border-grey-400">
							<div class="mb-1 text-sm font-medium text-gray-600">Специалист:</div>
							<div class="text-basic text-gray-900">
								{{ specialityTitle }}<br>
								{{ doctorTitle }}
							</div>
						</div>
						<div class="pb-2">
							<div class="mb-1 text-sm font-medium text-gray-600">Дата и время:</div>
							<div class="text-basic text-gray-900">
								{{ dateTitle }}
								в
								{{ time.id }}
							</div>
						</div>
						<af-button v-model="finishButtonState">Закрыть</af-button>
					</div>
				</template>
			</form>
		</div>
	</af-modal-dialog>
</template>

<script>
	import axios from 'axios';
	import { API_URL } from '@/constants';
	import { STATE } from '@/constants';
	// import { TIMER } from '@/constants';
	
	export default {
        name: 'fetch-appointment-dialog',
        props: {
			modalActive: Boolean,
			modalClinicId: String,
		},
		emits: ['modalHide'],
		data() {
			return {
				active: false,
				activeStep: 1,
				clinic: {
					id: null,
					items: [],
					state: STATE.DISABLED
				},
				speciality: {
					id: null,
					items: [],
					state: STATE.DISABLED
				},
				doctor: {
					id: null,
					items: [],
					state: STATE.DISABLED
				},
				date: {
					id: null,
					items: [],
					state: STATE.DISABLED,
					error: false
				},
				time: {
					id: null,
					items: [],
					state: STATE.DISABLED
				},
				patientName: {
					value: null,
					state: STATE.READY
				},
				patientPhoneNumber: {
					value: null,
					state: STATE.DISABLED
				},
				firstButtonState: STATE.DISABLED,
				fetchCodeButtonState: STATE.DISABLED,
				code: {
					value: null,
					state: STATE.DISABLED,
					// onceRequested: false,
				},
				// timer: {
				// 	enabled: false,
				// 	value: 10
				// },
				fetchAppointmentButtonState: STATE.DISABLED,
				finishButtonState: STATE.READY
			}
		},
		created() {
			this.DISABLED = STATE.DISABLED;
			this.LOADING = STATE.LOADING;
			this.READONLY = STATE.READONLY;
			this.READY = STATE.READY;
			this.ACTIVE = STATE.ACTIVE;
			this.VALID = STATE.VALID;
			this.CLICKED = STATE.CLICKED;
		},
		mounted() {
			this.fetchClinics();
		},
		computed: {
			clinicId() { return this.clinic.id; },
			clinicTitle() { return this.clinic.id ? this.clinic.items.find(key => key.id === this.clinic.id).title : '' },
			clinicItems() { return this.clinic.items.length; },
			clinicState() { return this.clinic.state; },
			specialityId() { return this.speciality.id; },
			specialityTitle() { return this.speciality.id ? this.speciality.items.find(key => key.id === this.speciality.id).title : '' },
			specialityItems() { return this.speciality.items.length; },
			specialityState() { return this.speciality.state; },
			doctorId() { return this.doctor.id; },
			doctorTitle() { return this.doctor.id ? this.doctor.items.find(key => key.id === this.doctor.id).title : '' },
			doctorItems() { return this.doctor.items.length; },
			doctorState() { return this.doctor.state; },
			dateId() { return this.date.id; },
			dateTitle() {
				const date = this.date.items.find(key => key.id === this.date.id);
				const monthTitles = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
				return `${date.day} ${monthTitles[date.month]} ${date.year}`
			},
			dateItems() { return this.date.items.length; },
			dateState() { return this.date.state; },
			timeId() { return this.time.id; },
			timeItems() { return this.time.items.length; },
			timeState() { return this.time.state; },
			patientNameState() { return this.patientName.state; },
			patientPhoneNumberState() { return this.patientPhoneNumber.state; },
			codeState() { return this.code.state; },
			// timerEnabled() { return this.timer.enabled; },
			// timerValue() { return this.timer.value; }
		},
		watch: {
			modalActive(value) {
				this.active = value;
			},
			active(value) {
				if (value) {
					this.clinic.id = this.modalClinicId;
				} else {
					this.activeStep = 1;
					this.clinic.id = null;
					this.speciality = {
						id: null,
						items: [],
						state: STATE.DISABLED
					};
					this.doctor = {
						id: null,
						items: [],
						state: STATE.DISABLED
					};
					this.date = {
						id: null,
						items: [],
						state: STATE.DISABLED,
						error: false
					};
					this.time = {
						id: null,
						items: [],
						state: STATE.DISABLED
					};
					this.patientName = {
						value: null,
						state: STATE.ACTIVE
					};
					this.patientPhoneNumber = {
						value: null,
						state: STATE.DISABLED
					},
					this.firstButtonState = STATE.DISABLED;
					this.fetchCodeButtonState = STATE.DISABLED;
					this.code = {
						value: null,
						state: STATE.DISABLED,
					};
					this.fetchAppointmentButtonState = STATE.DISABLED;
					this.finishButtonState = STATE.READY;
					this.$emit('modalHide');
				}
			},
			clinicId(value) {
				if (value) {
					this.fetchSpecialities();
				} else {
					this.clearSpecialities();
				}
			},
			clinicItems(length) {
				console.debug(`appointmentDialog: clinic.items = ${this.clinic.items.map(key => key.title)}`);
				switch(length) {
					case 0: {
						if (this.clinic.state !== STATE.LOADING) {
							this.clinic.state = STATE.DISABLED;
						}
						break
					}
					case 1: {
						this.clinic.state = STATE.READONLY;
						this.clinic.id = this.clinic.items[0].id;
						break
					}
					default: {
						this.clinic.state = STATE.READY;
						break
					}
				}
			},
			clinicState(value) {
				switch(value) {
					case STATE.LOADING: {
						this.clinic.id = null;
						this.clinic.items = [];
						break
					}
					case STATE.ACTIVE: {
						this.hideDropdowns('clinic');
						break;
					}
				}
			},
			specialityId(value) {
				if (value) {
					this.fetchDoctors();
				} else {
					this.clearDoctors();
				}
			},
			specialityItems(length) {
				console.debug(`appointmentDialog: speciality.items = ${this.speciality.items.map(key => key.title)}`);
				switch(length) {
					case 0: {
						if (this.speciality.state !== STATE.LOADING) {
							this.speciality.state = STATE.DISABLED;
						}
						break
					}
					case 1: {
						this.speciality.state = STATE.READONLY;
						this.speciality.id = this.speciality.items[0].id;
						break
					}
					default: {
						this.speciality.state = STATE.READY;
						break
					}
				}
			},
			specialityState(value) {
				switch(value) {
					case STATE.LOADING: {
						this.clearSpecialities();
						break
					}
					case STATE.ACTIVE: {
						this.hideDropdowns('speciality');
						break;
					}
				}
			},
			doctorId(value) {
				if (value) {
					this.fetchDates();
				} else {
					this.clearDates()
				}
			},
			doctorItems(length) {
				console.debug(`appointmentDialog: doctor.items = ${this.doctor.items.map(key => key.title)}`);
				switch(length) {
					case 0: {
						if (this.doctor.state !== STATE.LOADING) {
							this.doctor.state = STATE.DISABLED;
						}
						break
					}
					case 1: {
						this.doctor.state = STATE.READONLY;
						this.doctor.id = this.doctor.items[0].id;
						break
					}
					default: {
						this.doctor.state = STATE.READY;
						break
					}
				}
			},
			doctorState(value) {
				switch(value) {
					case STATE.LOADING: {
						this.clearDoctors();
						break
					}
					case STATE.ACTIVE: {
						this.hideDropdowns('doctor');
						break;
					}
				}
			},
			dateId(value) {
				if (value) {
					this.fetchTimes();
				} else {
					this.clearTimes()
				}
			},
			dateItems(length) {
				console.debug(`appointmentDialog: date.items = ${this.date.items.map(key => [key.year, key.month + 1, key.day].join('-'))}`);
				switch(length) {
					case 0: {
						if (this.date.state !== STATE.LOADING) {
							this.date.state = STATE.DISABLED;
						}
						break
					}
					case 1: {
						this.date.state = STATE.READONLY;
						this.date.id = this.date.items[0].id;
						break
					}
					default: {
						let result = [];
						const firstDate = this.date.items[0];
						let startDate = new Date(firstDate.year, firstDate.month, 1);
						const lastDate = this.date.items[this.date.items.length - 1];
						const stopDate = new Date(lastDate.year, lastDate.month + 1, 0);
						
						while (startDate <= stopDate) {
							let item = { day: startDate.getDate(), month: startDate.getMonth(), year: startDate.getFullYear() };
							if (item.day === 1) {
								item.weekDay = startDate.getDay();
							}
							let sheduleDate = this.date.items.find(key => (key.day === item.day) && (key.month === item.month) && (key.year === item.year));
							if (sheduleDate) {
								item.id = sheduleDate.id;
								item.times = sheduleDate.times;
							}
							result.push(item);
							startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);
						}
						this.date.items = result;
						this.date.state = STATE.READY;
						break
					}
				}
			},
			dateState(value) {
				switch(value) {
					case STATE.LOADING: {
						this.clearDates();
						break
					}
					case STATE.ACTIVE: {
						this.hideDropdowns('date');
						break;
					}
				}
			},
			timeId(value) {
				this.firstButtonState = value ? STATE.READY : STATE.DISABLED;
			},
			timeItems(length) {
				console.debug(`appointmentDialog: time.items = ${this.time.items}`);
				switch(length) {
					case 0: {
						this.time.state = STATE.DISABLED;
						break
					}
					case 1: {
						this.time.id = this.time.items[0];
						this.time.state = STATE.READONLY;
						break
					}
					default: {
						this.time.state = STATE.READY;
						break
					}
				}
			},
			timeState(value) {
				switch(value) {
					case STATE.LOADING: {
						break
					}
					case STATE.ACTIVE: {
						this.hideDropdowns('time');
						break;
					}
				}
			},
			firstButtonState(value) {
				if (value === STATE.CLICKED) {
					this.activeStep++;
				}
			},
			patientNameState(value) {
				this.patientPhoneNumber.state = (value === STATE.VALID) ? STATE.READY : (value === STATE.READONLY) ? STATE.READONLY : STATE.DISABLED;
			},
			patientPhoneNumberState(value) {
				this.fetchCodeButtonState = (value === STATE.VALID) ? STATE.READY: STATE.DISABLED;
			},
			fetchCodeButtonState(value) {
				if (value === STATE.CLICKED) {
					this.fetchCode();
				}
			},
			codeState(value) {
				this.fetchAppointmentButtonState = value === STATE.VALID ? STATE.READY : STATE.DISABLED;
			},
			fetchAppointmentButtonState(value) {
				if (value === STATE.CLICKED) {
					this.fetchAppointment();
				}
			},
			finishButtonState(value) {
				if (value === STATE.CLICKED) {
					this.active = false;
				}
			}
			// timerEnabled(value) {
			// 	if (value) {
            //         setTimeout(() => {
            //             this.timer.value--;
            //         }, 1000);
            //     }
			// },
			// timerValue: {
			// 	handler(value) {
			// 		if (this.timer.enabled) {
			// 			if (value > 0) {
			// 				setTimeout(() => {
			// 					this.timer.value--;
			// 				}, 1000);
			// 			} else {
			// 				this.timer.enabled = false;
			// 				this.fetchCodeButtonState = STATE.READY;
			// 			}
			// 		}
			// 	},
			// 	immediate: true
			// }
		},
		methods: {
			hideDropdowns(except = '') {
				if (except != "clinic" && this.clinic.state == STATE.ACTIVE) {
					this.clinic.state =  STATE.READY;
				}
				if (except !== "speciality" && this.speciality.state == STATE.ACTIVE) {
					this.speciality.state =  STATE.READY;
				}
				if (except !== "doctor" && this.doctor.state == STATE.ACTIVE) {
					this.doctor.state =  STATE.READY;
				}
				if (except !== "date" && this.date.state == STATE.ACTIVE) {
					this.date.state =  STATE.READY;
				}
				if (except !== "time" && this.time.state == STATE.ACTIVE) {
					this.time.state =  STATE.READY;
				}
			},
			onClick() {
				this.hideDropdowns();
			},
			async fetchClinics() {
				try {
					this.clinic.state = STATE.LOADING;
					const response = await axios.get([API_URL, 'appointment'].join('/'));
					if (response.status === 200) {
	          			this.clinic.items = response.data.clinics;
					}
				} catch (error) {
					console.error(`appointmentDialog: fetchClinics() catch ${error}`);
				}
			},
			clearSpecialities() {
				this.speciality.id = null;
				this.speciality.items = [];
			},
			async fetchSpecialities() {
				try {
					this.speciality.state = STATE.LOADING;
					const response = await axios.get([API_URL, 'appointment', this.clinic.id].join('/'));
					if (response.status === 200 && this.active) {
						this.speciality.items = response.data.specialities;
					}
				} catch (error) {
					console.error(`appointmentDialog: fetchSpecialities() catch ${error}`);
				}
			},
			clearDoctors() {
				this.doctor.id = null;
				this.doctor.items = [];
			},
			async fetchDoctors() {
				try {
					this.doctor.state = STATE.LOADING;
					const response = await axios.get([API_URL, 'appointment', this.clinic.id, this.speciality.id].join('/'));
					if (response.status === 200 && this.active) {
						this.doctor.items = response.data.doctors;
					}
				} catch (error) {
					console.error(`appointmentDialog: fetchDoctors() catch ${error}`);
				}
			},
			clearDates() {
				this.date.id = null;
				this.date.items = [];
				this.date.error = false;
			},
			async fetchDates() {
				try {
					this.date.state = STATE.LOADING;
					const response = await axios.get([API_URL, 'appointment', this.clinic.id, this.speciality.id, this.doctor.id].join('/'));
					if (response.status === 200 && this.active) {
						if (response.data.count) {
							this.date.items = response.data.schedules;
						} else {
							this.date.state = STATE.DISABLED;
							this.date.error = true;
						}
					}
				} catch (error) {
					console.error(`appointmentDialog: fetchDates() catch ${error}`);
				}
			},
			clearDateError() {
				if (this.doctor.items.length > 1) {
					this.doctor.id = null;
				} else {
					this.speciality.id = null;
				}
			},
			clearTimes() {
				this.time.id = null;
				this.time.items = [];
			},
			fetchTimes() {
				this.clearTimes();
				this.time.items = this.date.items.find(key => key.id === this.date.id).times;
			},
			async fetchCode() {
				try {
					this.fetchCodeButtonState = STATE.LOADING;
					const response = await axios.post([API_URL, 'appointment'].join('/'), {
						day_id: this.date.id,
						time: this.time.id,
						patient_name: this.patientName.value,
						phone_number: this.patientPhoneNumber.value,
						// once_requested: this.code.onceRequested
					});
					if (response.status === 200 && response.data.status && this.active) {
						this.patientName.state = STATE.READONLY;
						this.code.state = STATE.READY;
						// this.code.onceRequested = true;
					}
				} catch (error) {
					console.error(`appointmentDialog: fetchCode() catch ${error}`);
				} finally {
					this.fetchCodeButtonState = STATE.READY;
				}
			},
			async fetchAppointment() {
				try {
					this.fetchAppointmentButtonState = STATE.LOADING;
					const response = await axios.post([API_URL, 'appointment', 'confirm'].join('/'), {
						phone_number: this.patientPhoneNumber.value,
						code: this.code.value
					});
					if (response.status === 200 && response.data.status && this.active) {
						this.activeStep = 3;
					}
				} catch (error) {
					console.error(`appointmentDialog: fetchAppointment() catch ${error}`);
				} finally {
					this.fetchAppointmentButtonState = STATE.READY;
				}
			}
		}
	}
</script>

<style></style>