export const API_URL = 'https://api.anotanar.ru/api/v1';

export const STATE = Object.freeze({
    DISABLED: 0,
    LOADING: 1,
    READONLY: 2,
    READY: 3,
    ACTIVE: 4,
    VALID: 5,
    CLICKED: 6,
});

// export const TIMER = 10;
